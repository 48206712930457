import React, { SFC, useState } from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
import { Router } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';
import { syncHistoryWithStore, SynchronizedHistory } from 'mobx-react-router';
import { RootStore } from './stores/root';
import { storeContext } from './stores/context';
import { createGlobalStyle, ThemeContext } from 'styled-components';
import 'normalize.css';
import dark from './theme/dark';
import { Theme } from './theme/Theme';

const Global = createGlobalStyle`
  body {
    background-color: ${props => props.theme.appBackground};
    color: ${props => props.theme.textColor};
    font-family: ${props => props.theme.fontFamily};
  }
`;

interface RootProps {
  history: SynchronizedHistory;
  rootStore: RootStore;
}

const Root: SFC<RootProps> = ({ rootStore, history }) => {
  const [theme, setTheme] = useState<Theme>(dark);
  return (
    <ThemeContext.Provider value={theme}>
      <Router history={history}>
        <Global />
        <storeContext.Provider value={rootStore}>
          <App />
        </storeContext.Provider>
      </Router>
    </ThemeContext.Provider>
  );
};

const init = async () => {
  const browserHistory = createBrowserHistory();

  const rootStore = new RootStore();

  const history = syncHistoryWithStore(browserHistory, rootStore.routingStore);

  await rootStore.authStore.checkUserLoggedIn();

  ReactDOM.render(
    <Root history={history} rootStore={rootStore} />,
    document.getElementById('app')
  );
};

init();

if (module.hot) {
  module.hot.accept();
}

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useCurrentWeather } from '../hooks/weather';
import WeatherIcon from './weather/WeatherIcon';

const getF = kelvin => (((kelvin - 273.15) * 9) / 5 + 32).toFixed(1);

const WeatherRoot = styled.div`
  display: flex;
`;

const Temperature = styled.div`
  font-size: 3rem;
`;

const WeatherPrimary = styled.div``;

const WeatherDetails = styled.div`
  text-align: center;
`;

export default function OpenWeather() {
  const weatherdata = useCurrentWeather();
  if (!weatherdata) {
    return null;
  }

  const { description, id } = weatherdata.weather[0];
  const { temp, humidity } = weatherdata.main;

  const tempF = getF(temp);

  return (
    <WeatherRoot>
      <WeatherPrimary>
        <WeatherIcon code={id} />
      </WeatherPrimary>

      <WeatherDetails>
        <Temperature>{tempF}F</Temperature>
        <div>{description}</div>
        <i>{humidity}% humidity</i>
      </WeatherDetails>
    </WeatherRoot>
  );
}

import React, { useContext } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { storeContext } from '../stores/context';

const LinksSectionTitle = styled.h2``;
const LinksSectionList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
const LinksSectionListItem = styled.li`
  margin-bottom: 0.5rem;
  a {
    color: red;
    text-decoration: none;
  }
`;

const LinksSectionBase = styled.div``;
const LinksBoxBase = styled.div`
  display: flex;
  justify-content: center;

  > ${LinksSectionBase}:not(:first-child) {
    margin-left: 1rem;
  }
`;

const LinksSection = ({ category, links }) => {
  return (
    <LinksSectionBase>
      <LinksSectionTitle>{category}</LinksSectionTitle>
      <LinksSectionList>
        {links.map((link, index) => (
          <LinksSectionListItem key={index}>
            <a href={link.href} target="_blank">
              {link.description}
            </a>
          </LinksSectionListItem>
        ))}
      </LinksSectionList>
    </LinksSectionBase>
  );
};

function LinksBox() {
  const rootStore = useContext(storeContext);
  const dashboardData = rootStore.pinboardStore.dashboard;

  if (!dashboardData) {
    return null;
  }

  const categories = Object.entries(dashboardData).map(([key, value]) => ({
    category: key,
    links: value,
  }));

  return (
    <LinksBoxBase>
      {categories.map((category, index) => (
        <LinksSection
          key={index}
          category={category.category}
          links={category.links}
        />
      ))}
    </LinksBoxBase>
  );
}

export default observer(LinksBox);

import { unauthedApiClient, apiClient } from './client';

export interface LoginArgs {
  username: string;
  password: string;
}

export const logIn = (options: LoginArgs) =>
  unauthedApiClient.post('/account/login', options);

export const getUserInfo = () => apiClient.get('/account/me');

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import dateFormat from 'dateformat';

const ClockDate = styled.div``;
const ClockTime = styled.div``;

const ClockBase = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  ${ClockDate} {
    font-size: 1.25rem;
  }

  ${ClockTime} {
    font-size: 4rem;
  }
`;

interface ClockProps {
  className?: string;
}

const Clock: React.FC<ClockProps> = ({ className }) => {
  const getTime = () => {
    const now = new Date();
    const date = dateFormat(now, 'dddd, mmmm dS');
    const time = dateFormat(now, 'HH:MM');

    return {
      date,
      time,
    };
  };

  const [time, setTime] = useState(getTime());

  useEffect(() => {
    const timerReference = setInterval(() => setTime(getTime()), 500);
    return () => {
      clearInterval(timerReference);
    };
  }, []);

  return (
    <ClockBase className={className}>
      <ClockTime>{time.time}</ClockTime>
      <ClockDate>{time.date}</ClockDate>
    </ClockBase>
  );
};

export default Clock;

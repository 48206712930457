import React from 'react';
import 'weathericons/css/weather-icons.min.css';
//import 'weathericons/css/weather-icons-wind.min.css';
import { openWeatherToWeatherIcon } from './icon-lookup';
import styled from 'styled-components';

const WeatherIconBase = styled.i`
  font-size: 3rem;
  margin: 0.5rem;
  line-height: inherit;
`;

const WeatherIcon = ({ code }) => {
  let icon = openWeatherToWeatherIcon[`${code}`].icon;
  const prefix = 'wi wi-';

  // If we are not in the ranges mentioned above, add a day/night prefix.
  if (!(code > 699 && code < 800) && !(code > 899 && code < 1000)) {
    icon = 'day-' + icon;
  }

  // Finally tack on the prefix.
  icon = prefix + icon;

  return <WeatherIconBase className={icon} />;
};

export default WeatherIcon;

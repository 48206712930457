import { RouterStore } from 'mobx-react-router';
import { AuthStore } from './auth';
import { Pinboard } from './pinboard';

export class RootStore {
  routingStore = new RouterStore();
  authStore = new AuthStore(this);
  pinboardStore = new Pinboard();

  async hydrateUserData() {
    this.pinboardStore.fetchDashboard();
  }
}

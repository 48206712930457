import React, { useRef, useContext } from 'react';
import { storeContext } from '../stores/context';
import { observer } from 'mobx-react';

const Login: React.FC = () => {
  const usernameRef = useRef<HTMLInputElement>();
  const passwordRef = useRef<HTMLInputElement>();
  const storeCtx = useContext(storeContext);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const username = usernameRef.current.value;
    const password = passwordRef.current.value;

    storeCtx.authStore
      .logUserIn({
        username,
        password,
      })
      .catch(() => {
        usernameRef.current.value = '';
        passwordRef.current.value = '';
      });
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <input placeholder="username" ref={usernameRef} />
        <input placeholder="password" type="password" ref={passwordRef} />
        <button type="submit">Log in</button>
      </form>
    </div>
  );
};

export default observer(Login);

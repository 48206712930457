import { apiClient } from './client';

export interface PinboardLink {
  href: string;
  description: string;
  tags: string;
}

export interface PinboardDashboard {
  [key: string]: PinboardLink;
}

export const getDashboard = async () => {
  const resp = await apiClient.get<PinboardDashboard>(
    '/integrations/pinboard/dashboard'
  );
  return resp.data;
};

import React from 'react';
import AppBase from '../components/AppBase';
import { Switch, Route } from 'react-router-dom';
import Login from './Login';
import Index from './Index';

const App: React.FC = () => {
  return (
    <AppBase>
      <Switch>
        <Route path="/login" component={Login} />
        <Route component={Index} />
      </Switch>
    </AppBase>
  );
};

export default App;

import { observable, action, runInAction } from 'mobx';
import { PinboardDashboard, getDashboard } from '../api/pinboard';

export class Pinboard {
  @observable dashboard: PinboardDashboard;

  @action async fetchDashboard() {
    const data = await getDashboard();
    runInAction(() => {
      this.dashboard = data;
    });
  }
}

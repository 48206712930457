import { Theme } from './Theme';

export default <Theme>{
  appBackground: '#000',
  textColor: '#fff',

  primaryColor: 'orange',
  secondaryColor: 'red',
  tertiaryColor: 'gray',

  surfaceBackground: 'rgba(1,1,1, 0.5)',

  fontFamily: 'Arial, Helvetica, sans-serif',
};

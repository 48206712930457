import axios from 'axios';

const baseURL = process.env.API_URL;

export const unauthedApiClient = axios.create({
  baseURL,
  withCredentials: true,
});

export const apiClient = axios.create({
  baseURL,
  withCredentials: true,
});

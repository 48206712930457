import React from 'react';
import FullViewBox from '../components/FullViewBox';
import Clock from '../components/Clock';
import styled from 'styled-components';
import LinksBox from '../components/LinksBox';
import OpenWeather from '../components/OpenWeather';

const InfoBar = styled.div`
  display: flex;
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

const Index: React.FC = () => {
  return (
    <FullViewBox>
      <div>
        <InfoBar>
          <Clock />
          <Spacer />
          <OpenWeather />
        </InfoBar>
        <LinksBox />
      </div>
    </FullViewBox>
  );
};

export default Index;

import { useState, useEffect } from 'react';

export const useCoordinates = () => {
  const [location, setLocation] = useState(null);
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(loc => {
      setLocation(loc.coords);
    });
  }, []);
  return location;
};

import styled from 'styled-components';

const FullViewBox = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;

  justify-content: center;
  align-items: center;
`;

export default FullViewBox;

import { observable, action, runInAction } from 'mobx';
import { RootStore } from './root';
import { LoginArgs, logIn, getUserInfo } from '../api/auth';

export class AuthStore {
  @observable isLoggedIn: boolean = false;

  constructor(private rootStore: RootStore) {}

  @action async logUserIn(options: LoginArgs) {
    try {
      await logIn(options);
      runInAction(() => {
        this.isLoggedIn = true;
      });
      this.rootStore.routingStore.push('/');
      return this.rootStore.hydrateUserData();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  @action async checkUserLoggedIn() {
    let loggedIn = false;
    try {
      await getUserInfo();
      loggedIn = true;
      return this.rootStore.hydrateUserData();
    } catch (error) {
      loggedIn = false;
      this.rootStore.routingStore.push('/login');
    }

    runInAction(() => {
      this.isLoggedIn = loggedIn;
    });
  }
}

import { useState, useEffect } from 'react';
import { useCoordinates } from './coords';
import { apiClient } from '../api/client';

export const useCurrentWeather = () => {
  const [weatherdata, setWeatherdata] = useState(null);
  const coords = useCoordinates();
  useEffect(() => {
    if (coords) {
      const { latitude: lat, longitude: lon } = coords;
      apiClient
        .get('/weather', {
          params: { lat, lon },
        })
        .then(resp => setWeatherdata(resp.data));
    }
  }, [coords]);
  return weatherdata;
};
